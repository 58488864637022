import React, { Component } from 'react';
import genie from './gennieLogo.svg';

import TicTacToeGame from './TicTacToeGame'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            Welcome! to Dabbed out Gaming
          </p>
          <div className="gameArea">
                <div className="TicTacToeGame">
                      <TicTacToeGame/>
                </div>
          </div>
          <img src={genie} className="App-logo" alt="genie" />
        </header>
      </div>
    );
  }
}

export default App;
